import { useState, useEffect, useRef } from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import { sendMessage, fetchMessages, deleteConversation, clearUnreadCounter } from '../services/messageService';
import { getAIReply } from '../services/openaiService';

const ConversationView = ({ selectedNumber, onBack, lastUpdate }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [useTemplate, setUseTemplate] = useState(true);
  const [lastClearTime, setLastClearTime] = useState(0);
  const [isGeneratingAI, setIsGeneratingAI] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState('44px');
  const messagesEndRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const inputRef = useRef(null);
  const clearTimeoutRef = useRef(null);
  const previousMessageCountRef = useRef(0);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Only scroll when new messages arrive
  useEffect(() => {
    if (messages.length > previousMessageCountRef.current) {
      scrollToBottom();
    }
    previousMessageCountRef.current = messages.length;
  }, [messages]);

  // Initial scroll when component mounts
  useEffect(() => {
    scrollToBottom();
  }, []);

  const checkRecentMessages = (messages) => {
    const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
    return messages.some(message => 
      new Date(message.created_at) > twentyFourHoursAgo
    );
  };

  const loadMessages = async () => {
    try {
      const data = await fetchMessages(selectedNumber);
      
      // Sort messages by date
      const sortedMessages = data.sort((a, b) => 
        new Date(a.created_at) - new Date(b.created_at)
      );
      
      // Only update state if there are changes
      const hasChanges = JSON.stringify(sortedMessages) !== JSON.stringify(messages);
      if (hasChanges) {
        setMessages(sortedMessages);
        // Set template state based on recent messages
        setUseTemplate(!checkRecentMessages(sortedMessages));
      }

      setError(null);
    } catch (err) {
      setError('Failed to load conversation');
      console.error('Error fetching messages:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedNumber) {
      loadMessages();
      // Poll for new messages every 3 seconds
      const pollInterval = setInterval(loadMessages, 3000);
      return () => clearInterval(pollInterval);
    }
  }, [selectedNumber, lastUpdate]);

  const handleSendMessage = async (e) => {
    e?.preventDefault();
    if (!newMessage.trim()) return;

    const messageContent = newMessage;
    setNewMessage('');

    try {
      setLoading(true);
      await sendMessage(selectedNumber, messageContent, useTemplate);
      await loadMessages();
      scrollToBottom(); // Scroll after sending a message
    } catch (error) {
      console.error('Error sending message:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatMessageDate = (timestamp) => {
    const date = new Date(timestamp);
    if (isToday(date)) {
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    }
    return format(date, 'MMM d, yyyy');
  };

  // Function to clear unread counter
  const clearUnreadMessages = async () => {
    const now = Date.now();
    // Only clear if 5 seconds have passed since last clear
    if (now - lastClearTime >= 5000) {
      const result = await clearUnreadCounter(selectedNumber);
      if (result.success) {
        setLastClearTime(now);
      }
    }
  };

  // Set up visibility observer
  useEffect(() => {
    if (!messages.length) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const isVisible = entries.some(entry => entry.isIntersecting);
        if (isVisible) {
          // Clear after 5 seconds of visibility
          if (clearTimeoutRef.current) {
            clearTimeout(clearTimeoutRef.current);
          }
          clearTimeoutRef.current = setTimeout(clearUnreadMessages, 5000);
        }
      },
      {
        root: null,
        threshold: 0.5, // 50% visibility
      }
    );

    // Observe the messages container
    const messagesContainer = messagesEndRef.current?.parentElement;
    if (messagesContainer) {
      observer.observe(messagesContainer);
    }

    return () => {
      if (messagesContainer) {
        observer.unobserve(messagesContainer);
      }
      if (clearTimeoutRef.current) {
        clearTimeout(clearTimeoutRef.current);
      }
    };
  }, [messages, selectedNumber]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (clearTimeoutRef.current) {
        clearTimeout(clearTimeoutRef.current);
      }
    };
  }, []);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this conversation? This action cannot be undone.')) {
      const result = await deleteConversation(selectedNumber);
      if (result.success) {
        // Go back and trigger a refresh
        onBack();
        // Force a refresh of the conversation list by updating the lastUpdate timestamp
        if (typeof window !== 'undefined') {
          window.dispatchEvent(new CustomEvent('conversationDeleted'));
        }
      } else {
        alert('Failed to delete conversation: ' + (result.error || 'Unknown error'));
      }
    }
  };

  const handleAIReply = async () => {
    if (isGeneratingAI) return;
    setIsGeneratingAI(true);

    try {
      const aiReply = await getAIReply(messages);
      setNewMessage(aiReply); // Set the AI reply in the input field instead of sending
      
      // Focus the input and move cursor to end
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(aiReply.length, aiReply.length);
      }
    } catch (error) {
      console.error('Error getting AI reply:', error);
      setError('Failed to generate AI reply');
    } finally {
      setIsGeneratingAI(false);
    }
  };

  const shouldShowDate = (message, previousMessage) => {
    if (!previousMessage) return true;
    const messageDate = new Date(message.created_at);
    const previousMessageDate = new Date(previousMessage.created_at);
    return messageDate.toDateString() !== previousMessageDate.toDateString();
  };

  const adjustTextareaHeight = (textarea) => {
    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set new height based on scrollHeight, with a max of 32 lines (roughly)
    const newHeight = Math.min(textarea.scrollHeight, 32 * 1.5 * 16); // 16px is base font size
    textarea.style.height = `${newHeight}px`;
    setTextareaHeight(`${newHeight}px`);
  };

  // Update textarea height when AI reply is set
  useEffect(() => {
    if (inputRef.current && newMessage) {
      adjustTextareaHeight(inputRef.current);
    }
  }, [newMessage]);

  return (
    <div className="flex flex-col h-[calc(100vh-12rem)]">
      {/* Header */}
      <div className="flex items-center justify-between bg-white dark:bg-gray-800 p-4 shadow-sm">
        <div className="flex items-center">
          <button
            onClick={onBack}
            className="mr-4 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
            </svg>
          </button>
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            {selectedNumber.replace('whatsapp:', '')}
          </h2>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleAIReply}
            disabled={isGeneratingAI}
            className={`p-2 text-white bg-blue-600 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 ${
              isGeneratingAI ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            title="Get AI Reply"
          >
            <svg
              className={`w-6 h-6 ${isGeneratingAI ? 'animate-pulse' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
          </button>
          <button
            onClick={handleDelete}
            className="p-2 text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
            title="Delete Conversation"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto px-4 py-6" ref={scrollContainerRef}>
        {loading && messages.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
          </div>
        ) : error ? (
          <div className="text-red-600 dark:text-red-400 text-center">{error}</div>
        ) : messages.length === 0 ? (
          <div className="text-gray-500 dark:text-gray-400 text-center">No messages yet</div>
        ) : (
          <div className="space-y-4">
            {messages.map((message, index) => (
              <div key={message.id} className="flex flex-col">
                {shouldShowDate(message, messages[index - 1]) && (
                  <div className="text-center text-sm text-gray-500 dark:text-gray-400 my-4">
                    {formatMessageDate(message.created_at)}
                  </div>
                )}
                <div
                  className={`flex ${
                    message.direction === 'outbound' ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div
                    className={`max-w-[70%] rounded-lg px-4 py-2 ${
                      message.direction === 'outbound'
                        ? 'bg-pink-500 text-white'
                        : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white'
                    }`}
                  >
                    <p className="whitespace-pre-wrap break-words">{message.body}</p>
                    <div
                      className={`text-xs mt-1 ${
                        message.direction === 'outbound'
                          ? 'text-pink-100'
                          : 'text-gray-500 dark:text-gray-400'
                      }`}
                    >
                      {format(new Date(message.created_at), 'HH:mm')}
                      {message.direction === 'outbound' && message.status === 'delivered' && (
                        <span className="ml-2">✓✓</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        )}
      </div>

      {/* Message Input */}
      <div className="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 p-4">
        <form onSubmit={handleSendMessage} className="flex items-end space-x-2">
          <div className="flex-1">
            <textarea
              ref={inputRef}
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              onKeyDown={handleKeyPress}
              placeholder="Type a message..."
              className="w-full rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white p-3 min-h-[44px] max-h-[480px] resize-none leading-normal"
              style={{ height: textareaHeight }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <label className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-400">
              <input
                type="checkbox"
                checked={useTemplate}
                onChange={(e) => setUseTemplate(e.target.checked)}
                className="rounded border-gray-300 dark:border-gray-600 text-pink-500 focus:ring-pink-500"
              />
              <span>Use Template</span>
            </label>
            <button
              type="submit"
              disabled={loading || isGeneratingAI}
              className={`px-4 py-2 rounded-lg bg-pink-500 text-white ${
                loading || isGeneratingAI
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-pink-600'
              }`}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConversationView;
