import { API_BASE_URL } from '../config';

const OPENAI_SYSTEM_PROMPT = `You are a dental care professional, give a reply to the patient following on from the conversation below. You will not give anything more than standard advice, if entering into small talk it will be breif and limited, if the patient needs an appointmet you will simply send them to book an appointment at https://www.followapp.care/ if you think that they have an urgent need you will tell them to call 111, if they need to speak to someone but it is less urgent you will offer to call them, and ask for a good time of day. You will make no promises but say you will do your best to accomodate their needs`;

export const getAIReply = async (messages) => {
  try {
    const formattedMessages = messages.map(msg => ({
      role: msg.direction === 'inbound' ? 'user' : 'assistant',
      name: msg.direction === 'inbound' ? 'Patient' : 'Practice',
      content: msg.body
    }));

    const response = await fetch(`${API_BASE_URL}/api/ai-reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: [
          { role: 'system', content: OPENAI_SYSTEM_PROMPT },
          ...formattedMessages
        ]
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to get AI reply');
    }

    const data = await response.json();
    return data.reply;
  } catch (error) {
    console.error('Error getting AI reply:', error);
    throw error;
  }
};
