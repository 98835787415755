import { API_BASE_URL } from '../config';

export const sendMessage = async (to, body, useTemplate = true) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: to.replace('whatsapp:', ''),
        body,
        useTemplate
      }),
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || 'Failed to send message');
    }

    return data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const fetchMessages = async (phoneNumber = null) => {
  try {
    const url = phoneNumber 
      ? `${API_BASE_URL}/api/messages/${encodeURIComponent(phoneNumber)}`
      : `${API_BASE_URL}/api/messages/recent`;
      
    const response = await fetch(url);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Failed to fetch messages');
    }

    // Handle the new response format
    if (!data.data || !Array.isArray(data.data.messages)) {
      throw new Error('Invalid response format');
    }

    return data.data.messages;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

export const fetchConversations = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/conversations/recent`);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Failed to fetch conversations');
    }

    // Handle the new response format
    if (!data.data || !Array.isArray(data.data.conversations)) {
      throw new Error('Invalid response format');
    }

    return data.data.conversations;
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
};

export const deleteConversation = async (phoneNumber) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/conversations/${phoneNumber.replace('whatsapp:', '')}`, {
      method: 'DELETE',
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || 'Failed to delete conversation');
    }

    return { success: true };
  } catch (error) {
    console.error('Error deleting conversation:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

export const clearUnreadCounter = async (phoneNumber) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/conversations/${phoneNumber.replace('whatsapp:', '')}/clear-counter`, {
      method: 'POST'
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || 'Failed to clear unread counter');
    }

    return { success: true };
  } catch (error) {
    console.error('Error clearing unread counter:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};
