import { API_BASE_URL } from '../config';

class TwilioService {
  constructor() {
    this.apiUrl = API_BASE_URL;
  }

  async checkServerHealth() {
    try {
      const response = await fetch(`${this.apiUrl}/api/health`);
      if (!response.ok) {
        throw new Error('Health check failed');
      }
      const data = await response.json();
      return data.status === 'healthy';
    } catch (error) {
      console.error('Server health check failed:', error);
      return false;
    }
  }

  async sendWhatsAppMessage(to, body, useTemplate = true) {
    try {
      // Check if server is available
      const isHealthy = await this.checkServerHealth();
      if (!isHealthy) {
        return {
          success: false,
          error: 'Backend server is not available. Please try again later.'
        };
      }

      const response = await fetch(`${this.apiUrl}/api/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          to: to.startsWith('whatsapp:') ? to : `whatsapp:${to}`, 
          body, 
          useTemplate 
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        return {
          success: false,
          error: data.message || 'Failed to send message',
          details: data.error,
          debug: data.debug
        };
      }

      return {
        success: true,
        data: data.data,
        debug: data.debug
      };
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
      return {
        success: false,
        error: error.message || 'Network error occurred',
        details: 'Please check your connection and try again.'
      };
    }
  }

  async deleteConversation(phoneNumber) {
    try {
      const response = await fetch(`${this.apiUrl}/api/conversations/${phoneNumber}`, {
        method: 'DELETE'
      });

      const data = await response.json();
      return {
        success: data.status === 'success',
        error: data.status === 'error' ? data.message : null
      };
    } catch (error) {
      console.error('Error deleting conversation:', error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  // Method to handle incoming webhook from Twilio
  handleIncomingMessage(body) {
    const {
      From: from,
      To: to,
      Body: messageBody,
      MessageSid: messageSid,
      NumMedia: numMedia,
      MediaUrl0: mediaUrl
    } = body;

    return {
      from,
      to,
      body: messageBody,
      messageSid,
      numMedia: parseInt(numMedia, 10),
      mediaUrl
    };
  }
}

// Create a singleton instance
const twilioService = new TwilioService();
export default twilioService;
