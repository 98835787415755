import { useState } from 'react';
import { HiMenu, HiX, HiSun, HiMoon } from 'react-icons/hi';
import { useTheme } from '../ThemeContext';

const Navigation = ({ onLogout, showLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <span className="text-pink-600 dark:text-pink-400 text-xl font-bold">
              Follow App - WhatsApp POC
            </span>
          </div>
          
          {/* Mobile menu button, dark mode toggle, and logout */}
          <div className="flex items-center space-x-4">
            {showLogout && (
              <button
                onClick={onLogout}
                className="text-sm text-white bg-red-600 px-4 py-2 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Logout
              </button>
            )}
            <button
              onClick={toggleDarkMode}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
              aria-label="Toggle dark mode"
            >
              {isDarkMode ? (
                <HiSun className="h-6 w-6" />
              ) : (
                <HiMoon className="h-6 w-6" />
              )}
            </button>
            <button
              onClick={toggleMenu}
              className="md:hidden text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              {isOpen ? <HiX className="h-6 w-6" /> : <HiMenu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Add mobile menu items here if needed */}
              {showLogout && (
                <button
                  onClick={onLogout}
                  className="w-full text-left px-3 py-2 text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  Logout
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
