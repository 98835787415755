import { useState, useEffect } from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import ConversationView from './ConversationView';
import { fetchMessages } from '../services/messageService';

const MessageHistory = ({ setInConversation }) => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(Date.now());

  useEffect(() => {
    setInConversation(selectedNumber !== null);
  }, [selectedNumber, setInConversation]);

  // Listen for conversation deleted event
  useEffect(() => {
    const handleConversationDeleted = () => {
      setLastUpdate(Date.now());
      setSelectedNumber(null);
    };

    window.addEventListener('conversationDeleted', handleConversationDeleted);
    return () => window.removeEventListener('conversationDeleted', handleConversationDeleted);
  }, []);

  useEffect(() => {
    loadConversations();
    // Poll for new messages every 3 seconds
    const pollInterval = setInterval(loadConversations, 3000);
    return () => clearInterval(pollInterval);
  }, [lastUpdate]); // Added lastUpdate dependency

  const loadConversations = async () => {
    try {
      setLoading(true);
      const data = await fetchMessages();
      
      // Group messages by phone number
      const conversationMap = new Map();
      
      data.forEach(message => {
        const otherParty = message.direction === 'outbound' ? message.to_number : message.from_number;
        
        // Skip the Twilio number
        if (otherParty === '+14155238886') {
          return;
        }

        if (!conversationMap.has(otherParty)) {
          conversationMap.set(otherParty, {
            phoneNumber: otherParty,
            lastMessage: message,
            messageCount: 1,
            unreadCount: message.direction === 'inbound' ? 1 : 0
          });
        } else {
          const conv = conversationMap.get(otherParty);
          if (new Date(message.created_at) > new Date(conv.lastMessage.created_at)) {
            conv.lastMessage = message;
          }
          conv.messageCount++;
          if (message.direction === 'inbound') {
            conv.unreadCount = (conv.unreadCount || 0) + 1;
          }
        }
      });

      // Convert map to array and sort by last message date
      const sortedConversations = Array.from(conversationMap.values())
        .sort((a, b) => new Date(b.lastMessage.created_at) - new Date(a.lastMessage.created_at));

      setConversations(sortedConversations);
      setError(null);
    } catch (err) {
      setError('Failed to load conversations');
      console.error('Error loading conversations:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setSelectedNumber(null);
    setLastUpdate(Date.now()); // Refresh the list when returning
  };

  if (selectedNumber) {
    return (
      <ConversationView
        selectedNumber={selectedNumber}
        onBack={handleBack}
        lastUpdate={lastUpdate}
      />
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <div className="p-4 border-b dark:border-gray-700">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Messages</h2>
      </div>

      {loading && conversations.length === 0 ? (
        <div className="p-4 text-center text-gray-600 dark:text-gray-400">
          Loading conversations...
        </div>
      ) : error ? (
        <div className="p-4 text-center text-red-600 dark:text-red-400">
          {error}
        </div>
      ) : conversations.length === 0 ? (
        <div className="p-4 text-center text-gray-600 dark:text-gray-400">
          No conversations yet
        </div>
      ) : (
        <div className="divide-y dark:divide-gray-700">
          {conversations.map((conversation) => (
            <div
              key={conversation.phoneNumber}
              onClick={() => setSelectedNumber(conversation.phoneNumber)}
              className="p-4 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-150"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {conversation.phoneNumber.replace('whatsapp:', '')}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {conversation.lastMessage.body}
                  </p>
                </div>
                <div className="flex flex-col items-end">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {formatDistanceToNow(new Date(conversation.lastMessage.created_at), { addSuffix: true })}
                  </span>
                  {conversation.unreadCount > 0 && (
                    <span className="mt-1 px-2 py-1 text-xs bg-pink-500 text-white rounded-full">
                      {conversation.unreadCount}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MessageHistory;
