import { useState, useEffect } from 'react';
import MessageHistory from './components/MessageHistory';
import ConversationView from './components/ConversationView';
import MessageComposer from './components/MessageComposer';
import LoginPage from './components/LoginPage';
import Navigation from './components/Navigation';

function App() {
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [showComposer, setShowComposer] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [inConversation, setInConversation] = useState(false);

  useEffect(() => {
    const auth = localStorage.getItem('isAuthenticated');
    if (auth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    setSelectedNumber(null);
  };

  const handleBack = () => {
    setSelectedNumber(null);
    setInConversation(false);
  };

  const handleComposerClose = () => {
    setShowComposer(false);
  };

  if (!isAuthenticated) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navigation onLogout={handleLogout} showLogout={true} />
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Messages</h1>
        </div>
        <div className="grid grid-cols-12 gap-6">
          {selectedNumber ? (
            <div className="col-span-12">
              <ConversationView
                selectedNumber={selectedNumber}
                onBack={handleBack}
                lastUpdate={lastUpdate}
              />
            </div>
          ) : (
            <>
              <div className={`${
                showComposer 
                  ? 'lg:col-span-7 col-span-12 order-2 lg:order-1' 
                  : 'col-span-12'
              } transition-all duration-200`}>
                <MessageHistory
                  onSelectNumber={setSelectedNumber}
                  selectedNumber={selectedNumber}
                  lastUpdate={lastUpdate}
                  setInConversation={setInConversation}
                />
              </div>
              <div className={`lg:col-span-5 col-span-12 order-1 lg:order-2 transition-all duration-200 ${
                showComposer ? 'opacity-100 visible mb-6 lg:mb-0' : 'opacity-0 invisible absolute'
              }`}>
                <MessageComposer
                  onMessageSent={() => {
                    setLastUpdate(Date.now());
                    setShowComposer(false);
                  }}
                  isOpen={showComposer}
                  onClose={handleComposerClose}
                />
              </div>
              {!inConversation && (
                <button
                  onClick={() => setShowComposer(true)}
                  className="fixed bottom-8 right-8 p-4 bg-pink-500 text-white rounded-full shadow-lg hover:bg-pink-600 transition-colors duration-200 z-50"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
