import { useState } from 'react';
import twilioService from '../services/twilioService';

const MessageComposer = ({ isOpen, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [status, setStatus] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success' or 'error'
  const [errorDetails, setErrorDetails] = useState('');
  const [useTemplate, setUseTemplate] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    setStatus('');
    setStatusType('');
    setErrorDetails('');

    try {
      // Format phone number to remove spaces and ensure it starts with country code
      const formattedNumber = phoneNumber.replace(/\s+/g, '');
      if (!formattedNumber.startsWith('+')) {
        throw new Error('Phone number must start with country code (e.g., +1)');
      }

      const result = await twilioService.sendWhatsAppMessage(formattedNumber, message, useTemplate);
      
      if (result.success) {
        setStatusType('success');
        setStatus('Message sent successfully!');
        setPhoneNumber('');
        setMessage('');
        onClose();
      } else {
        setStatusType('error');
        setStatus(result.error || 'Failed to send message');
        if (result.details) {
          setErrorDetails(result.details);
        }
      }
    } catch (error) {
      setStatusType('error');
      setStatus(error.message || 'An unexpected error occurred');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 lg:p-6 w-full ${isOpen ? 'block' : 'hidden'}`}>
      <div className="flex justify-between items-center mb-4 lg:mb-6">
        <h2 className="text-xl lg:text-2xl font-semibold text-gray-900 dark:text-white">New Message</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <svg className="h-5 w-5 lg:h-6 lg:w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4 lg:space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 lg:mb-2">
            Phone Number (with country code)
          </label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+1234567890"
            className="w-full px-3 lg:px-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm lg:text-base"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 lg:mb-2">
            Message
          </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="3"
            className="w-full px-3 lg:px-4 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm lg:text-base"
            required
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="useTemplate"
            checked={useTemplate}
            onChange={(e) => setUseTemplate(e.target.checked)}
            className="h-4 w-4 text-pink-600 focus:ring-pink-500 border-gray-300 rounded"
          />
          <label htmlFor="useTemplate" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
            Use message template
          </label>
        </div>

        {status && (
          <div className={`p-3 lg:p-4 rounded-md ${
            statusType === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
          }`}>
            <p className="text-sm lg:text-base">{status}</p>
            {errorDetails && <p className="mt-1 lg:mt-2 text-xs lg:text-sm">{errorDetails}</p>}
          </div>
        )}

        <button
          type="submit"
          disabled={isSending}
          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 text-sm lg:text-base ${
            isSending ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isSending ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default MessageComposer;
