const isDevelopment = process.env.NODE_ENV === 'development';

// In development, use localhost. In production, use the Worker URL
const backendUrl = isDevelopment 
  ? 'http://127.0.0.1:8787'  // Wrangler dev server port
  : 'https://api.demopractice.co.uk';  // Production Cloudflare Worker URL

// We don't need to append /api anymore since our Worker handles routes directly
export const API_BASE_URL = isDevelopment 
  ? 'http://127.0.0.1:8787'
  : 'https://api.demopractice.co.uk';

export const BACKEND_URL = backendUrl;

export const APP_CONFIG = {
  isDevelopment,
  apiBaseUrl: API_BASE_URL,
  defaultPageSize: 20,
  pollingInterval: 3000, // 3 seconds
};
